<!--
 * @Date: 2021-07-01 14:56:49
 * @LastEditors: YuWenYun
 * @LastEditTime: 2021-07-12 15:54:26
 * @Description: 
 * @version: 
 * @Author: YuWenYun
-->
<!--test.vue-->
<template>
  <div class="cm-w-100 cm-h-100">
    <img ref="image" v-if="imageSrc" :src="imageSrc" alt="" class="cm-w-100 cm-h-100" style="display: block">
  </div>
</template>

<script>
export default {
  name: 'QkImage', // 这个名字很重要，它就是未来的标签名<qk-text></qk-text>
  props: {
    imageSrc: {
      type: String,
      default: ''
    }
  }
};
</script>

<style scoped>
</style>
