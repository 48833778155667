var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hottagCont"},[_c('div',{staticClass:"imgBox",style:({
      background: `${
        _vm.tagInfo.tagImg == '' || _vm.tagInfo.tagImg == undefined
          ? _vm.tagInfo.tagColor
          : 'url(' +
            this.tagInfo.tagImg.img +
            ')no-repeat bottom center ' +
            _vm.tagInfo.tagColor
      }`,
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    })},[_vm._v(" "+_vm._s(_vm.tagInfo.tagImg ? '' : _vm.tagInfo.hotName)+" ")]),_c('div',{staticClass:"guideLine"})])
}
var staticRenderFns = []

export { render, staticRenderFns }