<!--test.vue-->
<template>
  <div class="cm-w-100" :class="menu.isSwiping ? '' : 'swiper-no-swiping'">
    <div v-if="menu.labelType === '横版'" class="cm-w-100 cm-h-100">
      <swiper
        ref="menuSwiper"
        :options="swiperOptions"
        :style="{
          backgroundColor: menu.backgroundColor,
          borderRadius: menu.borderRadius + 'px'
        }"
        :class="menu.menuList.length <= 6 ? 'cm-flex-around' : ''"
        class="cm-w-100 cm-h-100 cm-flex-middle"
      >
        <swiper-slide
          v-for="(item, index) in menu.menuList"
          :key="item.id"
          class="cm-flex-column cm-center cm-h-100"
          :class="menu.menuList.length <= 6 ? 'cm-flex-1' : ''"
          :style="{ width: menu.menuList.length <= 6 ? 'auto' : '18.18%' }"
          style="height: auto;"
        >
          <el-image
            v-if="menu.menuType !== 'icon'"
            :src="menu.menuIndex === index ? item.imgSel : item.img"
            :class="menu.menuType === 'text' ? 'cm-icon-28' : 'cm-icon-16'"
          ></el-image>
          <span
            v-if="menu.menuType !== 'text'"
            class="cm-lh-1 cm-m-t-4"
            :style="{
              fontSize: menu.fontSize + 'px',
              color:
                menu.menuIndex === index
                  ? item.editForm.colorSel
                  : item.editForm.color
            }"
            >{{ item.label }}</span
          >
        </swiper-slide>
      </swiper>
    </div>
    <div v-if="menu.labelType === '竖版'" class="cm-h-100 cm-center">
      <swiper
        ref="menuSwiper"
        :options="swiperOptions"
        :style="{
          backgroundColor: menu.backgroundColor,
          borderRadius: menu.borderRadius + 'px',
          maxHeight: menu.menuList.length <= 6 ? '360px' : '340px'
        }"
        class="cm-flex-column cm-w-100 menu"
      >
        <swiper-slide
          v-for="(item, index) in menu.menuList"
          :key="item.id"
          @click="menu.menuIndex = index"
          class="cm-flex-column cm-center cm-shrink-0"
          style="height: 60px!important;"
        >
          <el-image
            v-if="menu.menuType !== 'icon'"
            :src="menu.menuIndex === index ? item.imgSel : item.img"
            :class="menu.menuType === 'text' ? 'cm-icon-28' : 'cm-icon-16'"
          ></el-image>
          <span
            v-if="menu.menuType !== 'text'"
            class="cm-lh-1 cm-m-t-4"
            :style="{
              fontSize: menu.fontSize + 'px',
              color:
                menu.menuIndex === index
                  ? item.editForm.colorSel
                  : item.editForm.color
            }"
            >{{ item.label }}</span
          >
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import { randomStr } from '@/util/common';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';
export default {
  name: 'QkMenu', // 这个名字很重要，它就是未来的标签名<qk-text></qk-text>
  components: {
    Swiper,
    SwiperSlide
  },
  props: {
    menu: {
      type: Object,
      default: () => ({
        labelType: '横版',
        backgroundColor: '#F0E9DE',
        borderRadius: 0,
        // 滑动
        isSwiping: false,
        menuList: [
          {
            // 全局导航
            checked: false,
            // 参数
            id: randomStr(10),
            img: '',
            imgSel: '',
            label: '菜单1',
            isInputShow: false,
            linkName: '',
            editForm: {
              name: '菜单1',
              color: '#444444',
              colorSel: '#0097BC',
              radio: '0',
              select0: { label: '', value: '' },
              select1: { label: '', value: '' },
              select2: { label: '', value: '' },
              select4: [
                {
                  pageUuid: '',
                  slidUuid: '',
                  ImgUuid: ''
                },
                {
                  pageUuid: '',
                  slidUuid: '',
                  ImgUuid: ''
                }
              ]
            }
          }
        ],
        input: '经典菜单',
        fontSize: 10,
        color: '',
        menuType: '',
        menuIndex: 0,
        viewType: '',
        swiperOptions: {
          direction: 'horizontal'
        }
      })
    }
  },
  data() {
    let vm = this;
    return {
      translateOld: 0,
      swiperOptions: {
        observer: true,
        observeParents: true,
        observeSlideChildren: true,
        freeMode: true,
        freeModeMomentum: false,
        resistanceRatio: 0,
        slidesPerView: 'auto',
        autoplay: false,
        loop: false,
        direction: 'horizontal',
        grabCursor: true,
        on: {
          imagesReady: function() {
            if (vm.menu.menuIndex === -1 && vm.$refs.menuSwiper.$swiper) {
              vm.$refs.menuSwiper.$swiper.slideTo(0, 0, false);
            }
          },
          tap: function() {
            // if (this.clickedIndex === undefined || vm.menu.menuIndex === this.clickedIndex) {
            if (this.clickedIndex === undefined) {
              return false;
            }
            vm.menu.menuIndex = this.clickedIndex;
            vm.$emit('menuClick', vm.menu.menuList[this.clickedIndex].id);
          },
          touchMove: function() {
            if (vm.translateOld !== this.getTranslate()) {
              vm.translateOld = this.getTranslate();
              vm.$emit('handleSlide', { translate: this.getTranslate() });
            }
          }
        }
      }
    };
  },
  watch: {
    'menu.labelType'() {
      if (this.menu.swiperOptions) {
        this.$refs.menuSwiper.$swiper.changeDirection(
          this.menu.swiperOptions.direction
        );
      }
    }
  },
  created() {
    if (this.menu.swiperOptions && this.menu.swiperOptions.direction) {
      this.swiperOptions['direction'] = this.menu.swiperOptions.direction;
    }
  }
};
</script>

<style scoped>
.menu >>> .swiper-wrapper {
  display: flex;
  flex-direction: column;
}
</style>
