<!--test.vue-->
<template>
  <!--异步加载轮播图的情况;-->
      <!-- :class="{
      'swiper-no-swiping': !swiperOptions.isSwiping,
      'stop-swiping': swiperOptions.isDrag
    }" -->
  <swiper
    v-if="swiperShow"
    :options="swiperOptions"
    ref="swiper"
    class="cm-w-100 cm-h-100 carousel"
    style="overflow: hidden"
  >
    <swiper-slide
      class="cm-w-100 cm-h-100 cm-pr"
      v-for="(item, index) in imgList"
      :key="index"
    >
      <img :src="item" class="cm-w-100 cm-h-100" style="object-fit: fill" />
    </swiper-slide>
    <!-- 分页器 -->
    <div
      class="swiper-pagination"
      slot="pagination"
      :class="swiperOptions.paginationPosition"
    ></div>
  </swiper>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';
export default {
  name: 'QkImageCarousel', // 这个名字很重要，它就是未来的标签名<qk-text></qk-text>
  components: {
    Swiper,
    SwiperSlide
  },
  props: {
    imageSrcList: {
      type: Array,
      default: () => []
    },
    swiperOption: {
      type: Object,
      default: () => ({
        autoplay: true,
        direction: 'horizontal',
        effect: 'slide',
        paginationPosition: 'pagination-b',
        isDisabled: false
      })
    }
  },
  data() {
    let vm = this;
    return {
      imgList: [],
      swiperShow: false,
      swiperOptions: {
        pagination: {
          el: '.swiper-pagination'
        },
        loop: false,
        observer: true,
        observeParents: true,
        autoplay: true,
        direction: 'horizontal',
        effect: 'slide',
        fadeEffect: {
          crossFade: true
        },
        paginationPosition: 'pagination-b',
        isSwiping: false,
        activeIndex: -1,
        initialSlide: 0,
        followFinger: false,
        touchRatio: 1,
        freeMode: false,
        freeModeMomentumBounce: false,
        grabCursor: true,
        on: {
          tap() {
            vm.$emit('menuClick', this.activeIndex);
          },
          touchEnd() {
            setTimeout(() => {
              vm.$emit('handleCut', { activeIndex: this.activeIndex });
            }, 1);
          }
        }
      }
    };
  },
  watch: {
    imageSrcList() {
      this.getList();
    },
    swiperOption: {
      handler: function() {
        if (this.swiperOption.initialSlide !== -1) {
          this.reset();
        }
      },
      deep: true
    }
  },
  mounted() {
    this.getList();
  },
  methods: {
    // 获取幻灯片列表
    getList() {
      let list = [];
      this.imageSrcList.forEach(res => {
        list.push(res.url);
      });
      this.imgList = list;
      this.reset();
    },
    // 重置组件
    reset() {
      this.swiperShow = false;
      // this.swiperOptions.isSwiping =
      //   this.swiperOption.isSwiping !== undefined
      //     ? this.swiperOption.isSwiping
      //     : this.swiperOptions.isSwiping;
      this.swiperOptions.isDrag =
        this.swiperOption.isDrag !== undefined
          ? this.swiperOption.isDrag
          : this.swiperOptions.isDrag;
      if (this.swiperOption.isDisabled) {
        this.swiperOptions.autoplay = false;
        this.swiperOptions['allowSlideNext'] = false;
        this.swiperOptions['allowSlidePrev'] = false;
      } else {
        this.swiperOptions.autoplay =
          this.swiperOption.autoplay !== undefined
            ? this.swiperOption.autoplay
            : this.swiperOptions.autoplay;
      }
      this.swiperOptions.direction = this.swiperOption.direction
        ? this.swiperOption.direction
        : this.swiperOptions.direction;
      this.swiperOptions.effect = this.swiperOption.effect
        ? this.swiperOption.effect
        : this.swiperOptions.effect;
      this.swiperOptions.paginationPosition = this.swiperOption
        .paginationPosition
        ? this.swiperOption.paginationPosition
        : this.swiperOptions.paginationPosition;
      // 处理手动之后不能滑动的问题
      this.swiperOptions.autoplay = this.swiperOptions.autoplay
        ? { disableOnInteraction: false }
        : this.swiperOptions.autoplay;
      this.swiperOptions.initialSlide =
        this.swiperOption.initialSlide !== -1
          ? this.swiperOption.initialSlide
          : 0;
      this.$nextTick(() => {
        this.swiperShow = true;
      });
    }
  }
};
</script>

<style scoped>
/* .carousel{
  pointer-events: none;
} */
</style>
