import mySetInterVal from './utils';
export default {
  data() {
    return {
      endDirection: '', // 最终滑动方向
      touchEndTimer: null,
      touchEndTimeout: null
    };
  },
  methods: {
    //获取当前帧是在末端还是在起始
    getFramePosition() {
      const len = this.animateList.length;
      // 起始位置的判断
      if (this.currentNum == 0) {
        return 1; //起始
      } else if (this.currentNum + 1 == len) {
        return 2; //末端
      } else {
        return 0;
      }
    },
    //向左滑动
    touchLeft() {
      if (!this.options.slidePlay) {
        return;
      }
      if (!this.allowTouch) {
        return;
      }
      let num = this.element.sort;
      if (num === 0) {
        this.nomalHanlde();
      } else {
        this.reverseHandle();
      }
    },
    // 正序
    nomalHanlde() {
      let getFramePosition = this.getFramePosition();
      if (getFramePosition == 2 && !this.options.loop) {
        return;
      }
      // const arrLen = this.animateList.length;
      if (this.options.loop && getFramePosition == 2) {
        this.currentNum = 0;
      } else {
        this.currentNum++;
      }
    },
    // 倒序
    reverseHandle() {
      let getFramePosition = this.getFramePosition();
      if (getFramePosition == 1 && !this.options.loop) {
        return;
      }
      const arrLen = this.animateList.length;

      if (this.options.loop && getFramePosition == 1) {
        this.currentNum = arrLen - 1;
      } else {
        this.currentNum--;
      }
    },
    //向右滑动
    touchRight(e) {
      // console.log(e.velocity);
      // this.vo = e.velocity;
      // this.starTime = e.timeStamp;
      // clearInterval(this.timer);
      // this.vt = e.velocity;
      // 计算加速度
      // this.a = (this.vt - this.vo) / (e.timeStamp - this.starTime);
      // this.vo = this.vt;
      if (!this.options.slidePlay) {
        return;
      }
      if (!this.allowTouch) {
        return;
      }
      let num = this.element.sort;
      if (num === 0) {
        this.reverseHandle();
      } else {
        this.nomalHanlde();
      }
    },
    //向上滑动
    touchUp() {
      if (!this.options.slidePlay) {
        return;
      }
      if (!this.allowTouch) {
        return;
      }
      let getFramePosition = this.getFramePosition();
      if (getFramePosition == 1 && !this.options.loop) {
        return;
      }
      const arrLen = this.animateList.length;
      if (this.options.loop && getFramePosition == 1) {
        this.currentNum = arrLen - 1;
      } else {
        this.currentNum--;
      }
    },
    //向下滑动
    touchDown() {
      if (!this.options.slidePlay) {
        return;
      }
      if (!this.allowTouch) {
        return;
      }
      let getFramePosition = this.getFramePosition();
      if (getFramePosition == 2 && !this.options.loop) {
        return;
      }
      if (this.options.loop && getFramePosition == 2) {
        this.currentNum = 0;
      } else {
        this.currentNum++;
      }
    },
    touchmove(e) {
      //这个很重要，安卓端阻止touchmove触发touchcancel的执行
    },
    touchMove(e) {
      e.preventDefault();
      //实时记录滑动方向，以便在touchend中执行
      this.endDirection = e.additionalEvent;
    },
    touchEnd(e) {
      if (!this.options.slidePlay) {
        return;
      }
      if (!this.allowTouch) {
        return;
      }
      // 如果等差数列存在 就将之前的定时器关闭
      if (this.a) {
        this.a.stop();
      }
      // 等差数列做缓冲效果
      this.a = new mySetInterVal(
        () => {
          if (this.endDirection == 'panright') {
            this.touchRight();
          }
          if (this.endDirection == 'panleft') {
            this.touchLeft();
          }
          if (this.endDirection == 'panup') {
            this.touchUp();
          }
          if (this.endDirection == 'pandown') {
            this.touchDown();
          }
        },
        10,
        1
      );
      this.a.start();

      this.touchEndTimeout = setTimeout(() => {
        this.a.stop();
      }, 400);
      // console.log(a);
    },
    touchStart(e) {
      this.starTime = e.timeStamp;
      clearInterval(this.touchEndTimer);
      this.touchEndTimer = null;
      clearTimeout(this.touchEndTimeout);
      this.touchEndTimeout = null;
      if (!this.options.slidePlay) {
        return;
      }
      if (!this.allowTouch) {
        return;
      }
      this.judgeBe();
    },
    touchCancel() {}
  }
};
