<!--
 * @Date: 2021-10-15 17:20:17
 * @LastEditors: YuWenYun
 * @LastEditTime: 2021-12-13 14:06:06
 * @Description: 
 * @version: 
 * @Author: YuWenYun
-->
<template>
  <div v-if="element.elName.indexOf('menu') > 0 || element.elName === 'qk-image-carousel'" class="quark-element-wrapper">
    <!--<component :is="element.elName" class="quark-element" v-bind="element.propsValue"/>-->
    <component ref="componentsTemplate" :is="element.elName" v-show="!element.isHide" @menuImgClick="handleMenuImgClick" @menuClick="handleClick" @transitionMove="handleClick" class="element-on-edit-pane cm-h-100" v-bind="{ ...element.propsValue, value: element.value, element: element, activePageIndex: activePageIndex, pageIndex: pageIndex }" />
  </div>
  <div v-else class="quark-element-wrapper" @click="handleClick">
    <!--<component :is="element.elName" class="quark-element" v-bind="element.propsValue"/>-->
    <component ref="componentsTemplate" :is="element.elName" v-show="!element.isHide" class="element-on-edit-pane cm-h-100" v-bind="{ ...element.propsValue, value: element.value, element: element, activePageIndex: activePageIndex, pageIndex: pageIndex }" @changeVideo="changeVideo" />
  </div>
</template>

<script>
import { qkRegisterComponentsObject } from '../plugins/index';
import runAnimations from '@/assetsH5/js/runAnimations';
import elementEvents from '@/assetsH5/mixins/elementEvents';
import { randomStr } from '@/util/common';

export default {
  name: 'components-template',
  components: {
    // 批量注册qk组件
    ...qkRegisterComponentsObject
  },
  props: {
    element: {
      type: Object,
      require: true
    },
    activePageIndex: Number,
    pageIndex: Number
  },
  mixins: [elementEvents],
  methods: {
    // 播放动画
    playAnimations() {
      this.$el.style.display = '';
      this.$el.unIde = randomStr(10);
      let cssText = this.$el.style.cssText;
      let animations = this.element.animations || [];
      runAnimations(this.$el, animations, false, () => {
        this.$el.style.cssText = cssText;
      });
    },
    // 终结动画
    finalAnimations() {
      this.$el.style.animationName = '';
      this.$el.style.animationDuration = '';
      this.$el.style.animationIterationCount = '';
      this.$el.style.animationDelay = '';
      this.$el.style.animationFillMode = '';
      this.$el.style.animationTimingFunction = '';
      this.$el.unIde = '';
      this.$el.style.display = 'none';
    },
    // 组件点击事件
    async handleClick(id, type) {
      // console.log('handleElementClick', this.element)
      this.$emit('handleElementClick', this.element, id, type);
    },
    changeVideo() {
      this.$emit('handleElementClick', this.element);
    },
     // 图片菜单点击事件
    handleMenuImgClick(obj) {
      console.log(obj, 73)
      this.$emit('handleMenuElementClick', obj);
    }
  }
};
</script>
