<template>
  <div class="container">
    <div
      v-if="titleSet.mainTitle.show"
      class="main-title"
      :style="{ color: titleSet.mainTitle.color }"
    >
      {{ titleSet.mainTitle.value }}
    </div>
    <div
      v-if="titleSet.subTitle.show"
      class="sub-title"
      :style="{ color: titleSet.subTitle.color }"
    >
      {{ titleSet.subTitle.value }}
    </div>
    <div class="form-box">
      <div class="input-items">
        <input
          class="input-ele"
          type="text"
          v-model.trim="formData.name"
          placeholder="请输入姓名"
          maxlength="8"
          :style="{
            'background-color': colorSet.inputBgColor,
            'border-color': colorSet.inputBorderColor,
          }"
        />
      </div>
      <div class="input-items">
        <input
          class="input-ele"
          type="text"
          v-model="formData.phone"
          placeholder="请输入手机号"
          maxlength="11"
          :style="{
            'background-color': colorSet.inputBgColor,
            'border-color': colorSet.inputBorderColor,
          }"
        />
      </div>
      <div class="input-items">
        <input
          class="input-ele"
          type="text"
          v-model="formData.verify"
          placeholder="请输入验证码"
          maxlength="8"
          :style="{
            'background-color': colorSet.inputBgColor,
            'border-color': colorSet.inputBorderColor,
          }"
        />
        <span
          class="verify-text"
          :style="{ color: colorSet.mainColor }"
          @click="getVerify"
          >{{ verifyText }}</span
        >
      </div>
    </div>
    <van-button
      type="primary"
      :loading="btnLoading"
      loading-type="spinner"
      class="submit-btn"
      :disabled="submitDisabled"
      :style="{
        'background-color': colorSet.mainColor,
        'border-color': colorSet.mainColor,
      }"
      @click="submitData"
      >提交</van-button
    >
  </div>
</template>
<script>
  import api from "@/api/content";
  import { bus } from "@/util/bus";
  // import { queryUrl } from '@/util/common';
  // import trackFn from '@/util/trackFn';
  import { mapState } from "vuex";

  export default {
    name: "QkForm",
    data() {
      return {
        mainTitle: "",
        subTitle: "",
        showMainTitle: true,
        showSubTitle: true,
        submitDisabled: false,
        formData: {
          name: "",
          phone: "",
          verify: "",
        },
        verifyText: "获取验证码",
        btnLoading: false,
        timer: null,
        isPreview: false,
      };
    },
    props: {
      form: {
        type: Object,
        default: () => {
          return {
            name: "",
            phone: "",
            verify: "",
          };
        },
      },
      titleSet: {
        type: Object,
        default: () => {
          return {
            mainTitle: {},
            subTitle: {},
          };
        },
      },
      colorSet: {
        type: Object,
        default: () => {
          return {};
        },
      },
      fnSet: {
        type: Object,
        default: () => {
          return {};
        },
      },
      element: Object,
    },
    computed: {
      ...mapState("app", ["paramsData"]),
    },
    created() {
      let params = this.paramsData.params;
      if (params.from) {
        this.isPreview = true;
      } else {
        this.isPreview = false;
      }
    },
    methods: {
      async getVerify() {
        if (this.isPreview) {
          this.$toast("预览下无法继续操作");
          return;
        }
        let reg = /^1\d{10}$/;
        if (!this.formData.phone) {
          this.$toast.fail("请输入手机号");
          return;
        }
        if (!reg.test(this.formData.phone)) {
          this.$toast.fail("请输入正确的手机号");
          return;
        }
        if (this.timer) {
          return;
        }
        try {
          await api.getMessageVerify({
            phone: this.formData.phone,
          });
          let time = 60;
          this.verifyText = `${time}S后重新获取`;
          this.timer = setInterval(() => {
            if (time === 0) {
              this.verifyText = `获取验证码`;
              this.timer = clearInterval(this.timer);
            } else {
              time--;
              this.verifyText = `${time}S后重新获取`;
            }
          }, 1000);
        } catch (error) {
          this.$toast(error.msg || "网络错误");
        }
      },
      async submitData() {
        if (this.isPreview) {
          this.$toast("预览下无法继续操作");
          return;
        }
        let reg = /^1\d{10}$/;
        if (!this.formData.name) {
          this.$toast.fail("请输入姓名");
        } else if (!this.formData.phone) {
          this.$toast.fail("请输入手机号");
        } else if (!reg.test(this.formData.phone)) {
          this.$toast.fail("请输入正确的手机号");
        } else if (!this.formData.verify) {
          this.$toast.fail("请输入验证码");
        } else {
          try {
            let params = {
              cmeId: this.paramsData.params.cmeId,
              estateId: this.paramsData.params.estateId || 0,
              elementId: this.element.uuid,
              formObject: this.formData,
            };
            if (this.paramsData.params.shareId) {
              params["shareId"] = this.paramsData.params.shareId;
            }
            params["extField"] = JSON.stringify(this.paramsData.params);
            await api.submit(params);
            if (this.fnSet.submitType === "skip") {
              bus.$emit("skipPage", {
                uuid: this.fnSet.linkId,
                index: this.fnSet.linkIndex,
              });
            } else {
              this.$toast("提交成功");
              this.submitDisabled = true;
            }
            window.TrackEvent.formSubmitTrace();
            if (this.formData.phone) {
              window.Redpacks.onTrigger({
                behCode: ["BEH-LEAVE-PHONE"],
                phone: this.formData.phone,
              });
            }
          } catch (error) {
            console.log(error);
            this.$toast(error.msg || "网络错误");
          }
        }
      },
    },
  };
</script>

<style lang="less" scoped>
input {
  border: none;
  background: none;
  outline: none;
  -webkit-appearance: none;
  -webkit-user-select: text;
  user-select: text;
}
.el-form-item {
  margin-bottom: 16px;
  position: relative;
}
input::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
}
.input-items {
  position: relative;
  margin-bottom: 16px;
  box-sizing: border-box;
  // padding: 0 1px;
  .input-ele {
    width: 100%;
    height: 40px;
    padding: 12px;
    box-sizing: border-box;
    background: #ffffff;
    border-radius: 2px;
    border: 1px solid #dadada;
    outline: none;
    -webkit-user-select: text !important;
  }
}
.verify-text {
  position: absolute;
  right: 12px;
  top: 12px;
  font-size: 12px;
  color: #4d78ff;
}
.submit-btn {
  width: 100%;
  border-radius: 2px;
}
.main-title {
  font-size: 16px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.8);
  line-height: 22px;
  text-align: left;
  margin-bottom: 6px;
}
.sub-title {
  font-size: 12px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.5);
  line-height: 14px;
  text-align: left;
  margin-bottom: 20px;
}
</style>
