<!--test.vue-->
<template>
  <div class="cm-pr cm-bg-black cm-center" style="overflow: hidden; height: 100%;">
    <!--    x5-video-orientation="portraint"  播放器支付的方向， landscape横屏，portraint竖屏，默认值为竖屏-->
    <!--    x-webkit-airplay="allow" 这个属性应该是使此视频支持ios的AirPlay功能。使用AirPlay可以直接从使用iOS的设备上的不同位置播放视频、音乐还有照片文件，也就是说通过AirPlay功能可以实现影音文件的无线播放，当然前提是播放的终端设备也要支持相应的功能-->
    <!--    :x5-playsinline="true"-->
    <video :class="video.noControls ? 'video-controls video-controls-fullscreen' : ''" class="cm-w-100 cm-h-100 video-ele" style="object-fit: contain" :src="video.videoSrc" ref="videoEle" :loop="video.loop" :muted="video.voice" :controls="video.control" preload="auto" :controlslist="video.noControls ? 'nodownload nofullscreen noremoteplayback' : 'nodownload noremoteplayback'" :disablePictureInPicture="true" :playsinline="true" :webkit-playsinline="true" :x5-video-player-fullscreen="true" x5-video-player-type="h5" @timeupdate="timeChange" @ended="handleEnded"></video>
    <!-- <div
      v-if="video.isPreview !== true"
      :style="{ width: video.width, height: video.width * 0.5625 }"
      class="cm-pa cm-center"
      style="
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.6);
      "
    >
      <i class="iconfont icon-bofang cm-color-white cm-fn-38"></i>
    </div> -->
  </div>
</template>

<script>
import { randomStr } from '@/util/common';

export default {
  name: 'QkVideo', // 这个名字很重要，它就是未来的标签名<qk-text></qk-text>
  props: {
    video: {
      type: Object,
      default: () => {
        return {
          width: '100%',
          height: '',
          videoSrc: '',
          type: 'handPlay',
          name: '',
          id: randomStr(5),
          isPreview: false,
          noControls: false,
          voice: true, // 静音
          control: true, // 状态栏默认不显示
          loop: false // 循环
        };
      }
    }
  },
  data() {
    return {
      firstFlag: true,
      loopFlag: true
    };
  },
  mounted() {
    let that = this;
    that.video.height = (that.video.width * 56.25) / 100;
    // console.log(this.video.loop);
    this.loopFlag = this.video.loop;
    // this.video.loop = false;
  },
  methods: {
    // 播放视频
    playVideo() {
      if (this.video.type === 'autoPlay') {
        if (this.$refs.videoEle.currentTime > 0) {
          this.$refs.videoEle.currentTime = 0;
        }
        if (window.WeixinJSBridge) {
          window.WeixinJSBridge.invoke(
            'getNetworkType',
            {},
            () => {
              this.$refs.videoEle.load();
            },
            false
          );
          this.$refs.videoEle.addEventListener('canplay', () => {
            this.$refs.videoEle.play();
          });
        } else {
          this.$refs.videoEle.play();
        }
        this.$refs.videoEle.volume = 1;
      }
    },
    // 暂停视频
    pauseVideo() {
      if (this.$refs.videoEle.paused || this.$refs.videoEle.currentTime > 0) {
        // if (window.WeixinJSBridge) {
        //   this.$refs.videoEle.removeEventListener('canplay');
        // }
        this.$refs.videoEle.pause();
        this.$refs.videoEle.volume = 0;
        this.$refs.videoEle.currentTime = 0;
      }
    },
    timeChange() {
      if (this.firstFlag) {
        return;
      }
      if (this.$refs.videoEle) {
        let time = Math.floor(this.$refs.videoEle.currentTime * 1000);
        if (this.video.loopEndTime && time > this.video.loopEndTime) {
          if (this.video.loop) {
            this.$refs.videoEle.currentTime = Math.floor(this.video.loopStartTime / 1000);
          } else {
            this.$refs.videoEle.pause();
          }
        }
      }
    },
    handleEnded() {
      this.firstFlag = false;
      if (this.loopFlag) {
        this.video.loop = this.loopFlag;
        if (!this.video.control && this.video.loopEndTime) {
          this.$refs.videoEle.currentTime = Math.floor(this.video.loopStartTime / 1000);
        }
        this.$refs.videoEle.play();
      }
    }
  }
};
</script>

<style scoped>
/*进度条*/
.video-controls::-webkit-media-controls-timeline {
  display: none;
}

/*全屏按钮*/
.video-controls-fullscreen::-webkit-media-controls-fullscreen-button {
  display: none;
}
</style>
