<!--
 * @Description: 
 * @Date: 2022-07-05 15:08:22
 * @LastEditors: tianjun
 * @LastEditTime: 2022-07-12 11:13:33
 * @FilePath: /ftd-cme-manage/src/plugins/menu-img/src/index.vue
-->
<!--test.vue-->
<template>
  <div class="menuImg-container">
    <!-- <div v-if="direction === 'row'" class="cm-flex-start row-box">
      <img
        class="menu-img"
        v-for="(item, index) in menuList"
        :key="index"
        :src="item.img"
        @click="handleClick(item)"
      />
    </div> -->
    <ul v-if="direction === 'row'" class="cm-flex-start row-box">
      <li class="row-li" v-for="(item, index) in menuList" :key="index">
        <img class="menu-img" :src="item.img" @click="handleClick(item)" />
        <!-- appId字段实际为原始id -->
        <wx-open-launch-weapp v-if="item.linkType == '3'" :username="item.appId" :path="item.pagePath" style="width: 100%; height: 100%; display: block; position: absolute; left: 0; top: 0; z-index: 2">
          <script type="text/wxtag-template">
            <style>.btn{ width:100%;height:100%;position: absolute;left:0;top:0;opacity:0;}</style>
            <button class="btn">打开小程序</button>
          </script>
        </wx-open-launch-weapp>
      </li>
    </ul>
    <ul v-if="direction === 'column'" class="cm-flex-column column-box">
      <li class="row-li" v-for="(item, index) in menuList" :key="index">
        <img class="menu-img" :src="item.img" @click="handleClick(item)" />
        <wx-open-launch-weapp v-if="item.linkType == '3'" :username="item.appId" :path="item.pagePath" style="width: 100%; height: 100%; display: block; position: absolute; left: 0; top: 0; z-index: 2">
          <script type="text/wxtag-template">
            <style>.btn{ width:100%;height:100%;position: absolute;left:0;top:0;opacity:0;}</style>
            <button class="btn">打开小程序</button>
          </script>
        </wx-open-launch-weapp>
      </li>
    </ul>
    <!-- <div v-if="direction === 'column'" class="cm-flex-column column-box">
      <img
        class="menu-img"
        v-for="(item, index) in menuList"
        :key="index"
        :src="item.img"
        @click="handleClick(item)"
        style="width: 100%; height: auto;"
      />
    </div> -->
  </div>
</template>

<script>
export default {
  name: 'QkMenuImg', // 这个名字很重要，它就是未来的标签名<qk-text></qk-text>
  components: {},
  props: {
    direction: {
      type: String,
      default: 'row',
    },
    menuList: {
      type: Array,
      default: () => {
        return [
          {
            img: '',
            imgText: `图片菜单1`,
            linkType: '0', // 0内部链接，1外部链接，2企业微信客服，3小程序，4拨打电话
            contentType: 10, // 关联0
            contentId: '', // 关联0
            contentTitle: '',
            href: '', // 关联1
            phone: '', //4关联3
            appId: '', // 关联3
            pagePath: '', // 关联3
            // companyId: '', // 关联2,企业id
            serviceLink: '', // 关联2，微信客服链接
          },
          {
            img: '',
            imgText: `图片菜单2`,
            linkType: '0', // 0内部链接，1外部链接，2企业微信客服，3小程序，4拨打电话
            contentType: 10, // 关联0
            contentId: '', // 关联0
            contentTitle: '',
            href: '', // 关联1
            phone: '', //4关联3
            appId: '', // 关联3
            pagePath: '', // 关联3
            // companyId: '', // 关联2,企业id
            serviceLink: '', // 关联2，微信客服链接
          },
        ];
      },
    },
    menuImg: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    let vm = this;
    return {
      translateOld: 0,
    };
  },
  watch: {},
  created() {},
  methods: {
    handleClick(value) {
      this.$emit('menuImgClick', value);
    },
  },
};
</script>

<style lang="less" scoped>
.menuImg-container {
  padding: 4px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  .row-box {
    // overflow-x: auto;
    box-sizing: border-box;
    height: 100%;
    .row-li {
      flex: 1;
      position: relative;
      &:not(:last-child) {
        margin-right: 4px;
      }
      .menu-img {
        // flex: 1;
        width: 100%;
        height: 100%;
      }
    }
  }
  .column-box {
    box-sizing: border-box;
    height: 100%;
    // overflow-y: auto;
    .row-li {
      height: 75px;
      // flex: 1;
      box-sizing: border-box;
      position: relative;
      &:not(:last-child) {
        margin-bottom: 4px;
      }
      .menu-img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
