<template>
  <div class="cm-w-100 cm-h-100 wrapper">
    <div class="cm-w-100 cm-h-100 code-image" v-if="codeSrcImg && codeType == '2'">
      <img  class="code-inner-img" :src="codeSrcImg" alt="" />
    </div>
    <div class="cm-w-100 cm-h-100 code-image" v-else ref="codeImg">
    </div>
    <div class="logo-wrapper" v-if="logoSrc && logoSwitch">
      <img :src="logoSrc" alt="" class="logo-img" />
    </div>
  </div>
</template>

<script>
import QRCode from 'qrcodejs2';
import { mapGetters } from 'vuex';
export default {
  name: 'QkCode', // 这个名字很重要，它就是未来的标签名<qk-text></qk-text>
  props: {
    codeType: {
      type: String,
      default: '1'
    },
    codeSrc: {
      type: String,
      default: ''
    },
    codeSrcImg: {
      type: String,
      default: ''
    },
    logoSwitch: {
      type: Boolean,
      default: true
    },
    logoSrc: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapGetters('editor', ['activeElement', 'activePage'])
  },
  watch: {
    codeSrc: {
      handler(value) {
        if (value && this.codeType == 1) {
          this.showQRcode();
        }
      }
    },
    codeSrcImg: {
      handler(value) {
        if (value && this.codeType == 2) {
          this.showQRcode();
        }
      }
    },
    codeType(value) {
      if (value === '1') {
        if (this.codeSrc) {
          this.showQRcode();
        }
      } else {
        if (this.codeSrcImg) {
          this.showQRcode();
        }
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.showQRcode();
    });
  },
  methods: {
    showQRcode() {
      if (this.$refs.codeImg) {
        this.$refs.codeImg.innerHTML = '';
      }
      // 二维码初始化
      this.$nextTick(() => {
        if (this.codeSrc && this.codeType === '1') {
          new QRCode(this.$refs.codeImg, {
            width: 140,
            height: 140,
            text: this.codeSrc,
            render: 'canvas'
          });
        } else if (this.codeSrcImg && this.codeType === '2') {

        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.wrapper {
  position: relative;
}
.code-image {
  padding: 2px;
  background-color: #fff;
  box-sizing: border-box;
  /deep/ img {
    width: 100%;
    height: 100%;
  }
}
.logo-wrapper {
  position: absolute;
  width: 23%;
  height: 23%;
  left: 50%;
  top: 50%;
  box-sizing: border-box;
  padding: 1px;
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  transform: translate(-50%, -50%);
  .logo-img {
    width: 100%;
    height: 100%;
  }
}
</style>
