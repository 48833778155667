/*
 * @Date: 2021-11-18 17:03:17
 * @LastEditors: YuWenYun
 * @LastEditTime: 2021-11-18 17:03:17
 * @Description:
 * @version:
 * @Author: YuWenYun
 */

// 为组件提供 install 方法，供组件对外按需引入
import Component from './src/index';
Component.install = Vue => {
  Vue.component(Component.name, Component);
};
export default Component;
