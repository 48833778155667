/*
 * @Date: 2021-11-23 19:50:53
 * @LastEditors: YuWenYun
 * @LastEditTime: 2021-11-24 11:29:41
 * @Description:
 * @version:
 * @Author: YuWenYun
 */
function mySetInterVal(fn, a, b) {
  this.a = a;
  this.b = b;
  this.time = 0;
  this.handle = -1;
  this.arr = [];
  this.start = () => {
    this.handle = setTimeout(() => {
      fn();
      this.time++;
      this.start();
      console.log(this.a + this.time * this.b);
    }, this.a + this.time * this.b);
    this.arr.push(this.handle);
  };

  this.stop = () => {
    this.arr.forEach(i => {
      clearTimeout(i);
    });
    console.log(this.arr, 111);

    this.time = 0;
    this.arr = [];
  };
}

export default mySetInterVal;
