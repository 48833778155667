var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"vote",staticClass:"qk-vote"},[(_vm.vote.content.title.length)?_c('div',{staticClass:"main-title",style:({ color: _vm.vote.colorStyle.title })},[_vm._v(" "+_vm._s(_vm.vote.content.title)+" ")]):_vm._e(),(_vm.getIsShow)?_c('div',{staticClass:"sub-title",style:({ color: _vm.vote.colorStyle.subhead })},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.vote.content.subhead.votePeople),expression:"vote.content.subhead.votePeople"}]},[_c('span',[_vm._v(_vm._s(_vm.participateNum || 0))]),_vm._v(" 人参与, ")]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.vote.content.subhead.isTime),expression:"vote.content.subhead.isTime"}]},[_vm._v(" 距离活动结束还有"+_vm._s(_vm.getResidueTime)+" ")])]):_vm._e(),(_vm.vote.type === '0')?_c('ul',{staticClass:"content-cards"},_vm._l((_vm.vote.content.contentList),function(item,index){return _c('li',{key:index + 'i',staticClass:"card",on:{"click":function($event){return _vm.chooseVote(item)}}},[_c('section',{class:{
          borderClass: _vm.vote.isChoose === '1' && item.isChooseVote,
        },staticStyle:{"display":"flex","justify-content":"center","box-sizing":"border-box"},style:({
          border: _vm.getBorder(item),
          borderRadius: _vm.vote.borderadius / 2 + '%',
        })},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(item.isVote),expression:"item.isVote"}],staticClass:"tags"},[_vm._v("已投")]),_c('div',{staticStyle:{"background-color":"#eeeeee","overflow":"hidden","width":"100%","height":"100%","display":"flex","justify-content":"center","align-items":"center"},style:({
            borderRadius: _vm.vote.borderadius / 2 + '%',
          })},[_c('img',{style:({
              width: _vm.imageWHs[index] && _vm.imageWHs[index].newWidth,
              height: _vm.imageWHs[index] && _vm.imageWHs[index].newHeight,
            }),attrs:{"src":item.img ? item.img : _vm.defaultImage,"alt":"","id":item.id},on:{"load":function($event){return _vm.loadHandle($event, index, item.id)}}})]),_c('div',{staticClass:"tips"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowLitterPlay(item)),expression:"isShowLitterPlay(item)"}],staticClass:"video-play"},[_c('svg-icon',{attrs:{"iconName":"video","iconWidth":14,"iconHeight":14}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShow),expression:"isShow"}],staticClass:"votes"},[_vm._v(_vm._s(item.optionNum || 0)+"票")])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowBigPlay(item)),expression:"isShowBigPlay(item)"}],staticClass:"big-play",on:{"click":function($event){$event.stopPropagation();return _vm.playVideo(item)}}},[_c('svg-icon',{attrs:{"iconName":"video","iconWidth":32,"iconHeight":32}})],1)]),_c('p',{style:({ color: _vm.vote.colorStyle.option })},[_vm._v(_vm._s(item.text))])])}),0):_c('ul',{staticClass:"content-list"},[(_vm.vote.isChoose === '0')?[(!_vm.isParticipate)?_c('el-radio-group',{staticStyle:{"overflow":"hidden"},on:{"change":_vm.changeHandle},model:{value:(_vm.radio),callback:function ($$v) {_vm.radio=$$v},expression:"radio"}},_vm._l((_vm.vote.content.contentList),function(item){return _c('el-radio',{key:item.id,staticClass:"radio-class",style:({
            borderRadius: (_vm.vote.borderadius / 200) * 41 + 'px',
            color: _vm.vote.colorStyle.option,
          }),attrs:{"label":item.id}},[_vm._v(" "+_vm._s(item.text)+" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShow),expression:"isShow"}],staticStyle:{"font-weight":"400","color":"rgba(0, 0, 0, 0.64)","line-height":"14px"}},[_vm._v(" "+_vm._s(item.optionNum || 0)+" ")])])}),1):_c('ul',{staticStyle:{"overflow":"hidden"}},_vm._l((_vm.vote.content.contentList),function(item){return _c('li',{key:item.id,staticClass:"radio-class-isParticipate",style:({
            borderRadius: (_vm.vote.borderadius / 200) * 41 + 'px',
            color: _vm.vote.colorStyle.option,
          })},[_c('section',{staticClass:"radio-head"},[_c('span',[_c('svg-icon',{directives:[{name:"show",rawName:"v-show",value:(item.isVote),expression:"item.isVote"}],attrs:{"icon-name":"hadChoose","iconWidth":16,"iconHeight":16}}),_vm._v(" "+_vm._s(item.text))],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShow),expression:"isShow"}],staticStyle:{"font-weight":"400","color":"rgba(0, 0, 0, 0.64)","line-height":"14px"}},[_vm._v(" "+_vm._s(item.optionNum ? item.optionNum + '(' + _vm.getPercentage(item) + '%)' : 0 + '(' + (0 + '%)'))+" ")])]),_c('div',{staticClass:"speed"},[_c('el-progress',{attrs:{"percentage":_vm.getPercentage(item),"show-text":false}})],1)])}),0)]:[(!_vm.isParticipate)?_c('el-checkbox-group',{staticStyle:{"overflow":"hidden"},model:{value:(_vm.voteIds),callback:function ($$v) {_vm.voteIds=$$v},expression:"voteIds"}},_vm._l((_vm.vote.content.contentList),function(item){return _c('el-checkbox',{key:item.id,staticClass:"radio-class",style:({
            borderRadius: (_vm.vote.borderadius / 200) * 41 + 'px',
            color: _vm.vote.colorStyle.option,
          }),attrs:{"label":item.id}},[_vm._v(_vm._s(item.text)+" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShow),expression:"isShow"}],staticStyle:{"font-weight":"400","color":"rgba(0, 0, 0, 0.64)","line-height":"14px"}},[_vm._v(" "+_vm._s(item.optionNum ? item.optionNum + '(' + _vm.getPercentage(item) + '%)' : 0 + '(' + (0 + '%)'))+" ")])])}),1):_c('ul',{staticStyle:{"overflow":"hidden"}},_vm._l((_vm.vote.content.contentList),function(item){return _c('li',{key:item.id,staticClass:"radio-class-isParticipate",style:({
            borderRadius: (_vm.vote.borderadius / 200) * 41 + 'px',
            color: _vm.vote.colorStyle.option,
          })},[_c('section',{staticClass:"radio-head"},[_c('span',[_c('svg-icon',{directives:[{name:"show",rawName:"v-show",value:(item.isVote),expression:"item.isVote"}],attrs:{"icon-name":"hadChoose","iconWidth":16,"iconHeight":16}}),_vm._v(" "+_vm._s(item.text))],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShow),expression:"isShow"}],staticStyle:{"font-weight":"400","color":"rgba(0, 0, 0, 0.64)","line-height":"14px"}},[_vm._v(" "+_vm._s(item.optionNum ? item.optionNum + '(' + _vm.getPercentage(item) + '%)' : 0 + '(' + (0 + '%)'))+" ")])]),_c('div',{staticClass:"speed"},[_c('el-progress',{attrs:{"percentage":_vm.getPercentage(item),"show-text":false}})],1)])}),0)]],2),(_vm.videoModle)?_c('van-popup',{attrs:{"get-container":"body","close-on-click-overlay":false,"overlay-style":{
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
    }},model:{value:(_vm.videoModle),callback:function ($$v) {_vm.videoModle=$$v},expression:"videoModle"}},[_c('div',{staticClass:"video-content",staticStyle:{"background-color":"transparent"},style:({
        width: _vm.innerWidth + 'px',
        height: _vm.innerHeight + 'px',
      }),on:{"click":function($event){if($event.target !== $event.currentTarget)return null;_vm.videoModle = false}}},[_c('div',{staticClass:"header"},[_c('span',[_vm._v(_vm._s(_vm.activeVideo.name.split('.')[0]))]),_c('div',{staticClass:"close",on:{"click":function($event){_vm.videoModle = false}}},[_c('svg-icon',{attrs:{"iconName":"close","iconWidth":24,"iconHeight":24}})],1)]),_c('div',{staticClass:"video",staticStyle:{"background":"#000000","height":"100%"},style:({
          width: _vm.innerWidth + 'px',
        })},[(_vm.activeVideo.url)?_c('video',{ref:"videoRef",staticStyle:{"height":"100%"},attrs:{"src":_vm.activeVideo.url,"controls":"","disablePictureInPicture":true,"playsinline":true,"webkit-playsinline":true,"x5-video-player-fullscreen":true,"x5-video-player-type":"h5","preload":"auto","width":_vm.innerWidth}}):_vm._e()])])]):_vm._e(),_c('button',{staticClass:"vote-button",style:({
      backgroundColor: !_vm.isParticipate ? _vm.vote.colorStyle.main : '#F7F7F7',
      borderRadius: (_vm.vote.borderadius / 200) * 41 + 'px',
      width: _vm.vote.type === '0' ? this.element.commonStyle.width + 'px' : '130px',
      color: !_vm.isParticipate ? '#FFFFFF' : '#CFCFCF',
      ..._vm.fixedStyle,
    }),attrs:{"id":_vm.element.uuid},on:{"click":_vm.toVote}},[_vm._v(" "+_vm._s(!_vm.isParticipate ? _vm.vote.voteButtonText : _vm.vote.haveVoteButtonText)+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }