<!--
 * @Date: 2021-07-01 14:56:49
 * @LastEditors: YuWenYun
 * @LastEditTime: 2021-07-09 17:26:23
 * @Description: 
 * @version: 
 * @Author: YuWenYun
-->
<template>
  <div class="frameCont">
    <img :src="frameImg.img" alt="" resizeMode="center" />
  </div>
</template>
<script>
export default {
  name: 'QkFrame',
  data() {
    return {};
  },
  props: {
    frameImg: {
      type: Object,
      default: () => {}
    }
  },
  computed: {

  }
};
</script>
<style lang="less" scoped>
.frameCont {
  display: inline-block;
  text-align: center;
  position: relative;
  margin-right: 10px;
  margin-bottom: 10px;
  width: 100%;
  height: 100%;

  img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    max-width: 100%;
    max-height: 100%;
    cursor: pointer;
    margin: 0 auto;
  }
}
</style>
