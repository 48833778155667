<!--
 * @Date: 2021-10-15 17:20:17
 * @LastEditors: YuWenYun
 * @LastEditTime: 2021-10-27 11:03:47
 * @Description: 
 * @version: 
 * @Author: YuWenYun
-->
<!--test.vue-->
<template>
  <div
    v-html="text.text"
    class="text-style"
    :style="{
      fontFamily: text.id,
      textAlign: element.commonStyle.textAligin
    }"
  ></div>
</template>

<script>
export default {
  name: 'QkText', // 这个名字很重要，它就是未来的标签名<qk-text></qk-text>
  props: {
    text: {
      type: Object,
      default: () => {
        return {
          text: '双击输入文字',
          id: '',
          svgUrl: '',
          fontUrl: '',
          fontSize: 12
        };
      }
    },
    textS: {
      type: Object,
      default: () => {
        return {
          text: '双击输入文字',
          id: '',
          svgUrl: '',
          fontUrl: '',
          fontSize: 12
        };
      }
    },
    element: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  mounted() {
    console.log(this.element, 111);
  },
  data() {
    return {
      defaultStyle: {
        height: 40
      }
    };
  }
};
</script>

<style scoped>
.text-style >>> div {
  font-family: inherit;
}
</style>
