<!--
 * @Date: 2022-03-17 16:12:04
 * @LastEditors: YuWenYun
 * @LastEditTime: 2022-07-27 16:00:46
 * @Description: 
 * @version: 
 * @Author: YuWenYun
-->
<template>
  <div class="custom-form">
    <van-form ref="form" @failed="onFailed" :show-error="false">
      <li
        v-for="(item, index) in customForm.formConfig"
        :key="index"
        :style="{
          marginBottom: customForm.lineHeight + 'px',
        }"
      >
        <!-- 普通输入框 -->
        <!-- <div v-if="item.type === 'input'">
          {{ item.rule[item.inputType] }}
        </div> -->
        <div class="input-class" v-if="item.type === 'input'">
          <section class="label" v-if="item.isShowLabel">
            <div
              class="custom-label-require"
              v-if="item.rule[item.inputType][0].required"
            >
              *
            </div>
            <div class="custom-label">
              {{ item.lable }}
            </div>
          </section>
          <van-field
            :maxlength="item.inputType === 'phone' ? 11 : '100'"
            v-model="form[item.id]"
            :placeholder="item.placeholder"
            :name="item.id"
            :rules="item.rule[item.inputType]"
          >
          </van-field>
        </div>

        <!-- 手机的验证码 -->
        <van-field
          v-model="form.captcha"
          placeholder="请输入验证码"
          name="captcha"
          v-if="showCaptcha(item)"
          :style="{
            marginTop: customForm.lineHeight + 'px',
          }"
          :rules="[
            { required: true, trigger: 'onBlur', message: '请输入验证码' },
          ]"
        >
          <template #button>
            <span
              class="verify-text"
              :style="{ color: customForm.colorStyle.main }"
              @click="getVerify(item)"
              >{{ verifyText }}</span
            >
          </template>
        </van-field>
        <div class="input-class" v-if="item.type === 'textarea'">
          <section class="label" v-if="item.isShowLabel">
            <div class="custom-label-require" v-if="item.required">*</div>
            <div class="custom-label">
              {{ item.lable }}
            </div>
          </section>
          <!-- 多行文本 -->
          <van-field
            v-model="form[item.id]"
            :rows="item.row"
            type="textarea"
            :maxlength="item.maxlength"
            :placeholder="item.placeholder"
            :show-word-limit="item.isShowWordNum"
            :rules="item.rule"
            :name="item.id"
          ></van-field>
        </div>

        <!-- 下拉框选择 -->
        <section v-if="item.type === 'select'" class="input-class">
          <section class="label" v-if="item.isShowLabel">
            <div class="custom-label-require" v-if="item.required">*</div>
            <div class="custom-label">
              {{ item.lable }}
            </div>
          </section>
          <van-field
            readonly
            clickable
            :name="item.id"
            :value="form[item.id]"
            :placeholder="item.placeholder"
            @click="selectShow[item.id] = true"
            :rules="item.rule"
          />
          <van-popup
            v-model="selectShow[item.id]"
            position="bottom"
            get-container="body"
            round
          >
            <van-picker
              show-toolbar
              :columns="getOptions(item.options)"
              value-key="lable"
              @confirm="onConfirm($event, { id: item.id, type: 'select' })"
              @cancel="selectShow[item.id] = false"
              :title="item.placeholder"
            />
          </van-popup>
        </section>
        <!-- 选项 多选 -->
        <section v-if="item.type === 'option'" class="input-class">
          <section class="label" v-if="item.isShowLabel">
            <div class="custom-label-require" v-if="item.required">*</div>
            <div class="custom-label">
              {{ item.lable }}
            </div>
          </section>
          <van-field
            :name="item.id"
            v-if="item.optionType === '1'"
            :rules="item.rule"
          >
            <template #input>
              <div>
                <div
                  style="color: initial; font-size: 12px; margin-bottom: 16px"
                >
                  {{ item.placeholder }}
                </div>
                <van-checkbox-group v-model="form[item.id]">
                  <van-checkbox
                    :name="i.value"
                    shape="square"
                    checked-color="#4d78ff"
                    v-for="(i, index) in getOptions(item.options)"
                    :key="index"
                    >{{ i.lable }}</van-checkbox
                  >
                </van-checkbox-group>
              </div>
            </template>
          </van-field>
          <!-- 单选 -->
          <van-field
            :name="item.id"
            v-if="item.optionType === '0'"
            :rules="item.rule"
          >
            <template #input>
              <div>
                <div
                  style="color: initial; font-size: 12px; margin-bottom: 16px"
                >
                  {{ item.placeholder }}
                </div>
                <van-radio-group v-model="form[item.id]">
                  <van-radio
                    :name="i.value"
                    checked-color="#4d78ff"
                    v-for="(i, index) in getOptions(item.options)"
                    :key="index"
                    >{{ i.lable }}</van-radio
                  >
                </van-radio-group>
              </div>
            </template>
          </van-field>
        </section>
        <!-- 选择时间 -->
        <!-- <template v-if="item.type === 'time'">
          
        </template> -->
        <section v-if="item.type === 'time'" class="input-class">
          <section class="label" v-if="item.isShowLabel">
            <div class="custom-label-require" v-if="item.required">*</div>
            <div class="custom-label">
              {{ item.lable }}
            </div>
          </section>
          <van-field
            readonly
            clickable
            :name="item.id"
            :value="form[item.id]"
            :placeholder="item.placeholder"
            @click="timeShow[item.id] = true"
            :rules="item.rule"
          />
          <van-popup
            v-model="timeShow[item.id]"
            position="bottom"
            get-container="body"
            round
          >
            <van-datetime-picker
              :type="getTimeType(item.timeType)"
              @confirm="
                onConfirm($event, {
                  id: item.id,
                  type: 'time',
                  item,
                })
              "
              @cancel="timeShow[item.id] = false"
              :formatter="formatter"
              :title="item.placeholder"
            />
          </van-popup>
        </section>
        <!-- 地区级联 -->
        <section v-if="item.type === 'address'" class="input-class">
          <section class="label" v-if="item.isShowLabel">
            <div class="custom-label-require" v-if="item.required">*</div>
            <div class="custom-label">
              {{ item.lable }}
            </div>
          </section>
          <van-field
            v-model="form[item.id]"
            readonly
            :placeholder="item.placeholder"
            @click="addressShow[item.id] = true"
            :rules="item.rule"
          />
          <van-popup
            v-model="addressShow[item.id]"
            round
            position="bottom"
            get-container="body"
          >
            <van-cascader
              v-model="cascaderValue"
              :title="item.placeholder"
              :options="options"
              @close="addressShow[item.id] = false"
              @finish="onFinish($event, item)"
              :field-names="{
                text: 'regionName',
                value: 'regionName',
                children: 'childrenRegions',
              }"
            />
          </van-popup>
        </section>
      </li>

      <van-button
        block
        @click="submit"
        :disabled="submitDisabled"
        :style="getSubmitStyle"
        >{{ customForm.submitConfig.label }}</van-button
      >
    </van-form>
  </div>
</template>
<script>
  // import { Form,Field,RadioGroup, Radio } from 'vant';、
  import * as dayjs from "dayjs";
  import api from "@/api/content";
  import { mapState } from "vuex";
  import { getDataType } from "@/util/util";
  export default {
    name: "QkCustomForm",
    props: {
      customForm: {
        type: Object,
        default: () => {
          return {
            colorStyle: {
              main: "rgba(77, 120, 255, 1)", // 主题颜色
              submitColr: "rgba(255, 255, 255, 1)", // 提交文案颜色
              inputColor: "rgba(101, 101, 101, 1)", // 录入文字颜色
              formBgc: "rgba(255, 255, 255, 1)", // 表单背景颜色
              formBorderColr: "rgba(218, 218, 218, 1)", // 表单边框的颜色
            },
            formConfig: [
              {
                id: createUUID(),
                type: "input",
                title: "输入框",
                lable: "手机",
                inputType: "phone", // 文本框的类型 phone email name normal
                placeholder: "请输入手机号",
                isValidata: true,
                isDisabled: true,
                isReg: true,
                rule: {
                  phone: [
                    {
                      required: true,
                      message: "手机号码不能为空",
                      trigger: "onBlur",
                    },
                    {
                      // 自定义校验规则
                      pattern: /^1\d{10}$/,
                      message: "请输入正确格式的手机号码",
                      trigger: "onBlur",
                    },
                  ],
                  email: [
                    {
                      required: true,
                      message: "邮箱不能为空",
                      trigger: "onBlur",
                    },
                    {
                      // 自定义校验规则
                      pattern:
                        /^\w+((.\w+)|(-\w+))@[A-Za-z0-9]+((.|-)[A-Za-z0-9]+).[A-Za-z0-9]+$/,
                      message: "请输入正确格式的邮箱",
                      trigger: "onBlur",
                    },
                  ],
                  name: [
                    {
                      required: true,
                      message: "名称不能为空",
                      trigger: "onBlur",
                      minlength: 1,
                      maxlength: 99,
                    },
                  ],
                  normal: [
                    {
                      required: true,
                      message: "文本不能为空",
                      trigger: "onBlur",
                      minlength: 1,
                      maxlength: 99,
                    },
                  ],
                }, //校验的正则
                required: true, // 是否是必填、
              },
            ], // 表单的配置 顺序就是
            borderadius: 0, // 圆角
            lineHeight: 12, // 行高
            submitConfig: {
              label: "提交",
              linkType: 0, // 0代表浮窗提示 1代跳转页面 2跳转外联
              link: "", // 页面是id 外联是url
            },
            limitCount: "1", // 1每人一次 2每人一天一次
            limitStartTime: dayjs().format("YYYY-MM-DD HH:mm"), // 开始时间
            limitEndTime: dayjs().add(7, "day").format("YYYY-MM-DD HH:mm"), // 结束时间
          };
        },
      },
      element: Object,
    },
    data() {
      return {
        form: {}, //表单对象
        submitDisabled: false,
        rules: {
          phone: {
            // 自定义校验规则
            pattern: /1\d{10}/,
            message: "请输入正确格式的手机号码",
            trigger: "onBlur",
          },
          email: {
            // 自定义校验规则
            pattern:
              /^\w+((.\w+)|(-\w+))@[A-Za-z0-9]+((.|-)[A-Za-z0-9]+).[A-Za-z0-9]+$/,
            message: "请输入正确格式的邮箱",
            trigger: "onBlur",
          },
          // name: {
          //   validator: null,
          //   trigger: 'onBlur'
          // },
          // normal: {
          //   validator: null,
          //   trigger: 'onBlur'
          // }
        }, // 关于手机、邮箱的校验
        selectShow: {}, //关于下拉框的展示
        options: [],
        addressShow: {},
        cascaderValue: "",
        timeShow: {}, // 关于时间的弹窗展示
        verifyText: "获取验证码",
        timer: null,
        phoneId: null,
      };
    },
    methods: {
      onFailed(e) {
        console.log(e);
      },
      /**
       * @description: 表单初始化
       * @param {*}
       * @return {*}
       */

      init() {},
      /**
       * @description: 固定下面提交按钮
       * @param {*}
       * @return {*}
       */

      fixedHandle() {
        let top, height;
        this.isEval = false;
        // 判断投票按钮的展示位置
        this.$nextTick(() => {
          let buttonDom = document.getElementById(this.element.uuid);
          top = buttonDom.offsetTop + this.element.commonStyle.top;
          height = buttonDom.getBoundingClientRect().height;
          if (
            top > window.innerHeight - height &&
            this.fatherNode.pages[this.pageIndex].type === "lang"
          ) {
            // 固定定位
            this.fixed = true;
          } else {
            this.fixed = false;
          }
          this.$bus.$on("langScroll", (scroll) => {
            // 如果滑动的页面与当前页面是一个页面就执行下列逻辑
            if (scroll.index === this.pageIndex) {
              // 当页面滚动到一定时，下面提交的按钮就取消fixed，并跟随主体走
              let show = top - window.innerHeight - height;
              // console.log(scroll.scrollTop);
              if (show < scroll.scrollTop) {
                this.fixed = false;
              } else {
                this.fixed = true;
              }
            }
          });
        });
      },

      /**
       * @description: 获取地址
       * @param {*}
       * @return {*}
       */

      async getAreaData() {
        try {
          let res = await api.areaOptionsAPI(3);
          this.options = res;
        } catch (error) {
          console.log(error, "ERROR");
        }
      },
      onFinish({ selectedOptions }, item) {
        this.form[item.id] = selectedOptions
          .map((option) => option.regionName)
          .join("/");
        this.addressShow[item.id] = false;
      },
      /**
       * @description: 自定义 时间展示内容
       * @param {*} type
       * @param {*} val
       * @return {*}
       */

      formatter(type, val) {
        if (type === "year") {
          return val + "年";
        }
        if (type === "month") {
          return val + "月";
        }
        if (type === "day") {
          return val + "日";
        }
        if (type === "hour") {
          return val + "时";
        }
        if (type === "minute") {
          return val + "分";
        }
        return val;
      },
      /**
       * @description: 选择框确认
       * @param {*}obj 传进来的参数
       * @return {*}
       */

      onConfirm(value, obj) {
        let { id, type, item } = obj;
        if (type === "select") {
          // 下拉
          this.form[id] = value.value;
        } else {
          // 时间
          if (item.timeType.length === 2) {
            this.form[id] = dayjs(value).format("YYYY-MM-DD HH:mm");
          } else if (item.timeType[0] === "date") {
            this.form[id] = dayjs(value).format("YYYY-MM-DD");
          } else {
            this.form[id] = value;
          }
        }
        this[`${type}Show`][id] = false;
      },
      /**
       * @description: 提交
       * @param {*}
       * @return {*}
       */

      submit() {
        if (
          this.paramsData.params.from &&
          this.paramsData.params.from === "preview"
        ) {
          this.$toast("预览下无法继续操作");
          return;
        }
        this.$refs.form
          .validate()
          .then(async () => {
            // this.$toast.success('提交成功');
            try {
              let obj = JSON.parse(JSON.stringify(this.form));
              // 将数组类型转为字符串
              for (const key in obj) {
                if (getDataType(obj[key]) === "array") {
                  obj[key] = obj[key].toString();
                }
              }
              // 复选框
              let params = {
                cmeId: this.paramsData.params.cmeId,
                estateId: this.paramsData.params.estateId || 0,
                elementId: this.element.uuid,
                formObject: obj,
              };
              if (this.paramsData.params.shareId) {
                params["shareId"] = this.paramsData.params.shareId;
              }
              params["extField"] = JSON.stringify(this.paramsData.params);
              await api.submit(params);
              try {
                window.TrackEvent.formSubmitTrace();
                if (obj[this.phoneId]) {
                  window.Redpacks.onTrigger({
                    behCode: ["BEH-LEAVE-PHONE"],
                    phone: obj[this.phoneId],
                  });
                }
              } catch (error) {}
              //  console.log(res,'rrrrrr');
              let { linkType, link } = this.customForm.submitConfig;
              // 结束后跳转到哪
              if (linkType === 1) {
                this.$bus.$emit("handleSkipPage", { linkId: link });
              } else if (linkType === 2 && link) {
                window.location.href = link;
              } else {
                this.$toast("提交成功");
                if (
                  this.customForm.limitCount === "1" ||
                  this.customForm.limitCount === "2"
                ) {
                  this.submitDisabled = true;
                }
              }
            } catch (error) {
              this.$toast(error.msg);
              console.log(error, "ERROR");
            }
          })
          .catch((err) => {
            console.log(err);
            // this.$toast.fail('提交失败');
          });
      },
      /**
       * @description: 设置全局属性
       * @param {*}
       * @return {*}
       */

      setAttr() {
        let selectDom = document.querySelectorAll(".van-field__control");
        if (selectDom.length) {
          let str = "-webkit-user-select: auto !important;";
          for (const key in this.getStyle) {
            let newKey = key.replace(/[A-Z]/g, (match) => {
              return "-" + match.toLowerCase();
            });
            str += `${newKey} : ${this.getStyle[key]};`;
          }
          selectDom.forEach((i) => {
            i.setAttribute("style", str);
          });
        }
      },
      /**
       * @description: 获取验证码
       * @param {*} 手机表单配置
       * @return {*}
       */

      async getVerify(item) {
        // 存在from代表是预览
        if (
          this.paramsData.params.from &&
          this.paramsData.params.from === "preview"
        ) {
          // 预览
          this.$toast("预览下无法继续操作");
          return;
        }
        let reg = /^1\d{10}$/;

        if (!this.form[item.id]) {
          this.$toast.fail("请输入手机号");
          return;
        }
        if (!reg.test(this.form[item.id])) {
          this.$toast.fail("请输入正确的手机号");
          return;
        }
        if (this.timer) {
          return;
        }
        try {
          await api.getMessageVerify({
            phone: this.form[item.id],
          });
          let time = 60;
          this.verifyText = `${time}S后重新获取`;
          this.timer = setInterval(() => {
            if (time === 0) {
              this.verifyText = `获取验证码`;
              this.timer = clearInterval(this.timer);
            } else {
              time--;
              this.verifyText = `${time}S后重新获取`;
            }
          }, 1000);
        } catch (error) {
          this.$toast(error.msg || "网络错误");
        }
      },
    },
    created() {
      let arr = ["select", "time", "address"];
      this.customForm.formConfig.forEach((i) => {
        // 判断当前是文本 ‘并且是校验正则
        if (i.type === "input" && i.isReg) {
          if (i.inputType === "normal" || i.inputType === "name") {
            // 编写校验规则
            let minValidator = (val) => {
              return val.length >= i.rule[i.inputType][0].minlength;
            };
            let maxValidator = (val) => {
              return val.length <= i.rule[i.inputType][0].maxlength;
            };
            i.rule[i.inputType].push({
              validator: minValidator,
              trigger: "onBlur",
              message: `字数最少为${i.rule[i.inputType][0].minlength}个`,
            });
            i.rule[i.inputType].push({
              validator: maxValidator,
              trigger: "onBlur",
              message: `字数最多为${i.rule[i.inputType][0].maxlength}个`,
            });
          } else if (i.inputType === "phone") {
            this.phoneId = i.id;
          } else {
            i.rule[i.inputType].push(this.rules[i.inputType]);
          }
        }
        // i.type === 'option' && i.optionType === '1' 代表多选

        this.$set(
          this.form,
          i.id,
          i.type === "option" && i.optionType === "1" ? [] : ""
        );
        // 添加下拉的选项的展示
        if (arr.includes(i.type)) {
          this.$set(this[`${i.type}Show`], i.id, false);
        }
      });
      console.log(this.customForm, "00000");
      this.getAreaData();
      // console.log(this.selectShow,this.timeShow, this.form);
    },
    mounted() {
      this.$nextTick(() => {
        this.setAttr();
      });
    },
    computed: {
      ...mapState("app", ["paramsData"]),
      getSubmitStyle() {
        let obj = {
          borderRadius: (this.customForm.borderadius / 200) * 48 + "px",
          color: this.customForm.colorStyle.submitColr,
          backgroundColor: this.customForm.colorStyle.main,
          border: "none",
        };
        return obj;
      },
      getStyle() {
        let style = {
          borderRadius: (this.customForm.borderadius / 200) * 40 + "px",
          color: this.customForm.colorStyle.inputColor,
          backgroundColor: this.customForm.colorStyle.formBgc,
          borderColor: this.customForm.colorStyle.formBorderColr,
        };

        return style;
      },
      getOptions() {
        return function (options) {
          options.split("\n");
          let arr = [];
          options.split("\n").forEach((i) => {
            let obj = {
              lable: i,
              value: i || "-",
            };
            arr[arr.length] = obj;
          });
          return arr;
        };
      },
      /**
       * @description: 获取时间表单的类型
       * @param {*}
       * @return {*}
       */

      getTimeType() {
        return function (timeArr) {
          if (timeArr.length === 2) {
            // 日期时间
            return "datetime";
          } else if (timeArr[0] === "time") {
            return "time";
          } else {
            return "date";
          }
        };
      },
      // 展示手机验证码
      showCaptcha() {
        return function (item) {
          if (
            item.type === "input" &&
            item.inputType === "phone" &&
            item.isValidata
          ) {
            return true;
          } else {
            return false;
          }
        };
      },
    },
  };
</script>
<style lang="less" scoped>
/deep/.van-field__button {
  padding: 0;
}
.input-class {
  display: flex;
  flex-direction: column;
  .label {
    display: flex;
    .custom-label-require {
      color: rgba(250, 93, 93, 1);
      font-size: 12px;
      font-weight: 500;
      margin-right: 4px;
    }
    .custom-label {
      margin-bottom: 12px;
      color: rgba(0, 0, 0, 0.8);
      font-size: 12px;

      font-weight: 500;
      text-align: left;
    }
  }
}
.verify-text {
  position: absolute;
  right: 12px;
  top: 12px;
  font-size: 12px;
  color: #4d78ff;
}
/deep/.van-cell {
  padding: 0;
  background-color: transparent;
}
/deep/.van-field__body {
  textarea {
    height: auto;
  }
}
/deep/.van-field__word-limit {
  position: absolute;
  bottom: 0;
  right: 10px;
}
/deep/.van-field__control {
  border-style: solid;
  border-width: 1px;
  min-height: 40px;
  padding: 11px 13px !important;
  font: initial;
  font-size: 12px;
}
/deep/.van-field__error-message {
  position: absolute;
  bottom: 0;
  left: 13px;
  font: initial;
  font-size: 10px;
}
/deep/.van-checkbox,
/deep/.van-radio {
  margin-bottom: 16px;
}
</style>
