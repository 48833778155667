<template>
  <div class="cm-text-left stop-swiping " style="width:100%;height:100%">
    <div v-loading="loading" element-loading-background="rgba(0, 0, 0, 0)" v-if="animateList.length" class="frameCont" v-hammer:panmove="touchMove" v-hammer:pancancel="touchCancel" v-hammer:panstart="touchStart" v-hammer:panend="touchEnd" v-hammer:panleft="touchLeft" v-hammer:panright="touchRight" v-hammer:panup="touchUp" v-hammer:pan.down="touchDown" @touchmove="touchmove">
      <img v-for="(item, index) in mergeImgLists" :src="item" :key="index" :style="{ opacity: currentNum == index ? '1' : '0' }" alt="" ondragstart="return false;" object-fit="contain" style="border:1px solid rgba(0,0,0,0);overflow:hidden;" />
    </div>
    <div class="blankCont cm-fn-14" v-else>
      <i>请添加序列帧素材</i>
    </div>
  </div>
</template>
<script>
import child from './child'; //滑动功能
export default {
  name: 'QkAnimate',
  props: {
    animate: {
      type: Object,
      default: () => ({
        imgList: [], //图片列表
        playOptions: {
          speed: 0,
          slidePlay: false,
          autoPlay: true,
          loop: false
        }, //播放的参数
        isSetSize: true
      })
    },
    element: Object,
    activePageIndex: Number,
    pageIndex: Number
  },
  mixins: [child], //混入滑动操作部分
  data() {
    return {
      isFirst: true,
      //序列帧播放器对象
      keyFrames: null,
      //加载中
      loading: false,
      //用户操作权限
      userPlayOption: {
        userControl: false,
        mode: '3'
      },
      imgArrLoad: [],
      timer: null, //计时器
      currentNum: 0, //当前帧的下标
      mergeImgLists: [], //融合的加载变化帧
      allowTouch: false, //是否允许滑动操作
      a: null // 等差数列实例
    };
  },
  computed: {
    //图片列表
    animateList: {
      get() {
        let newArr = [];
        if (this.animate.imgList) {
          this.animate.imgList.forEach(x => {
            newArr.push(x.img);
          });
        }
        return newArr;
      }
    },
    //获取属性配置
    options: {
      get() {
        return this.animate.playOptions ? this.animate.playOptions : {};
      }
    }
  },
  methods: {
    // 触发序列帧
    trigerAnimate() {
      if (!this.isFirst) {
        return;
      }
      this.isFirst = false;
      this.judgeBe();
      if (this.animateList.length > 0) {
        //加载图片
        this.mergeImgLists = [];
        this.currentNum = 0;
        this.preload(this.animateList, 0, false);
        // this.initPlayer();
      }
    },

    initPlayer() {
      let arrLen = this.animateList.length;
      if (arrLen > 0) {
        const { speed, loop, autoPlay } = this.options;
        if (autoPlay) {
          this.timer = setInterval(() => {
            if (this.currentNum == arrLen - 1) {
              this.currentNum = 0;
              if (!loop) {
                this.judgeBe();
                return;
              }
            } else {
              this.currentNum++;
            }
          }, ((speed + 1) * 1000) / 25);
        }
      }
    },
    //播放器公共操作方法
    keyFramesFix(fix) {
      this.keyFrames[fix]();
    },
    //判断是否存在播放器
    judgeBe() {
      clearInterval(this.timer);
      this.timer = null;
    },
    //arr需要迭代的数组，index数组下标,smallloadend小图是否加载完成
    preload(arr, index, smallLoadEnd = false) {
      if (!smallLoadEnd) {
        this.loading = true;
        this.allowTouch = false;
      }
      index = index || 0;
      if (index >= this.animateList.length && !smallLoadEnd) {
        this.loading = false;
        this.initPlayer();
        this.preload(this.animateList, 0, true);
        this.allowTouch = true;
        this.isFirst = true;
      }
      if (arr && arr.length > index) {
        let img = new Image();
        let link = '';
        if (smallLoadEnd) {
          link = arr[index];
        } else {
          link = arr[index] + '?x-oss-process=image/resize,h_100,m_lfit';
        }
        img.src = link;
        img.onload = () => {
          smallLoadEnd ? this.$set(this.mergeImgLists, index, link) : this.mergeImgLists.push(link);
          this.preload(arr, index + 1, smallLoadEnd);
        };
        img.οnerrοr = () => {
          smallLoadEnd ? this.$set(this.mergeImgLists, index, link) : this.mergeImgLists.push(link);
          this.preload(arr, index + 1, smallLoadEnd);
        };
      }
    }
  },

  mounted() {
    // this.$nextTick(()=>{
    // })
    // setTimeout(() => {
    // this.trigerAnimate();
    // }, 2000);
    // this.$Bus.$on('trigerAnimate', val => {
    //   if (val === this.uuid) {
    //     this.trigerAnimate();
    //   }
    // });
  },
  watch: {
    activePageIndex: {
      handler(val) {
        console.log(val, '[[');
        if (val === this.pageIndex) {
          this.trigerAnimate();
        }
      },
      immediate:true
    },
  
  },
  destroyed() {
    // this.$Bus.$off('trigerAnimate')
    console.log('销毁了');
    //销毁播放器
    this.judgeBe();
  }
};
</script>

<style lang="less" scoped>
.blankCont {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  i {
    display: block;
    width: 200px;
    text-align: center;
    line-height: 30px;
    background: #999;
    color: #fff;
    font-style: normal;
    border-radius: 4px;
  }
}
.frameCont {
  display: inline-block;
  text-align: center;
  position: relative;
  margin-right: 10px;
  margin-bottom: 10px;
  width: 100%;
  height: 100%;
  // transform: translate3d(0, 0, 0);
  img {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;
    cursor: pointer;
    margin: 0 auto;
  }
}
</style>
