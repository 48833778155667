<template>
  <div class="hottagCont">
    <div
      class="imgBox"
      :style="{
        background: `${
          tagInfo.tagImg == '' || tagInfo.tagImg == undefined
            ? tagInfo.tagColor
            : 'url(' +
              this.tagInfo.tagImg.img +
              ')no-repeat bottom center ' +
              tagInfo.tagColor
        }`,
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }"
    >
      <!-- 有背景图的情况下不显示文字 -->
      {{ tagInfo.tagImg ? '' : tagInfo.hotName }}
    </div>
    <div class="guideLine"></div>
  </div>
</template>
<script>
export default {
  name: 'QkHottag',
  computed: {

  },
  props: {
    tagInfo: {
      type: Object,
      default: () => {}
    }
  }
};
</script>
<style lang="less" scoped>
.hottagCont {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .guideLine {
    width: 1px;
    height: 40px;
    background: rgb(227, 209, 19);
  }
}
</style>
