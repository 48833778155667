var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container",style:(_vm.h5Data.pageCommonStyle),attrs:{"id":"h5"}},[_c('fixed-button-new'),(_vm.noData)?_c('div',{staticClass:"no-data w-100 h-100"},[_c('img',{staticClass:"no-data-img",attrs:{"src":require("@/assets/images/no-data.png"),"alt":""}}),_c('div',{staticClass:"no-data-text"},[_vm._v("暂无数据")])]):_c('swiper',{ref:"pageSwiper",staticClass:"page-swiper",attrs:{"options":_vm.swiperOption}},_vm._l((_vm.pages),function(page,i){return _c('swiper-slide',{key:i,class:{ 'stop-swiping': _vm.commonConfig.isAllDisabledScroll || page.config.isDisableCurrentPageScroll },staticStyle:{"overflow":"hidden","transform":"translate3d(0, 0, 0)"}},[_c('div',{ref:"slideWrapper",refInFor:true,staticClass:"w-100 h-100 slide-scroll",class:{ 'in-iframe': _vm.inIframe },on:{"touchstart":function($event){return _vm.handleAnStart(page.type)},"touchmove":function($event){return _vm.handleAnMove(page.type, i)},"scroll":function($event){return _vm.handleLongScroll(page.commonStyle.pageBg.pageH, page.type, i)}}},[_c('div',{class:{ 'stop-swiping': page.commonStyle.pageBg.pageH > _vm.innerHeight },staticStyle:{"background-size":"100% auto","background-repeat":"no-repeat","position":"relative","overflow":"hidden"},style:({
            width: page.commonStyle.pageBg.pageW + 'px',
            height: page.commonStyle.pageBg.pageH + 'px',
            backgroundColor: page.commonStyle.backgroundColor,
            backgroundImage: 'url(' + _vm.h5Data.pageCommonStyle.backgroundImage + ')',
          })},[_vm._l((page.elements),function(item,index){return _c('componentsTemplate',{key:index,ref:"componentsTemplateEle",refInFor:true,style:({ ..._vm.getCommonStyle(item.commonStyle, _vm.scalingRatio, item), top: _vm.fixedH(item, i), width: _vm.fixedWidth(item) }),attrs:{"element":_vm.getElement(item, index, i),"activePageIndex":_vm.activePageIndex,"pageIndex":i},on:{"handleElementClick":function($event){return _vm.handleElementClick(item)},"handleMenuElementClick":function($event){return _vm.handleMenuElementClick($event, index)}}})}),(page.commonStyle.backgroundImage)?_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(page.commonStyle.backgroundImage),expression:"page.commonStyle.backgroundImage"}],staticStyle:{"object-fit":"fill","max-width":"100%"}}):_vm._e()],2)])])}),1),(_vm.pageLoading || _vm.apiLoading)?_c('loading',{attrs:{"loadingAnimate":_vm.commonConfig.loadingAnimate}}):_vm._e(),(_vm.pageLoading || _vm.apiLoading)?_c('starPage',{attrs:{"commonConfig":_vm.commonConfig}}):_vm._e(),(_vm.isShowDialog)?_c('van-popup',{ref:"popup",attrs:{"overlay-style":{
      backgroundColor: _vm.dialogPage.commonStyle.backgroundColor,
    }},model:{value:(_vm.isShowDialog),callback:function ($$v) {_vm.isShowDialog=$$v},expression:"isShowDialog"}},[_c('div',{staticStyle:{"background-size":"100% auto","background-repeat":"no-repeat"},style:({
        width: _vm.dialogPage.commonStyle.pageBg.pageW + 'px',
        height: _vm.dialogPage.commonStyle.pageBg.pageH + 'px',
      }),on:{"click":function($event){if($event.target !== $event.currentTarget)return null;return _vm.backClck(_vm.dialogPage)}}},_vm._l((_vm.dialogPage.elements),function(item,index){return _c('componentsTemplate',{key:index,ref:"popupComponentsTemplateEle",refInFor:true,style:({ ..._vm.getCommonStyle(item.commonStyle, _vm.scalingRatio, item) }),attrs:{"element":item},on:{"handleElementClick":function($event){return _vm.handleElementClick(item)},"handleMenuElementClick":function($event){return _vm.handleMenuElementClick($event, index)}}})}),1)]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }