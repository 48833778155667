var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cm-w-100",class:_vm.menu.isSwiping ? '' : 'swiper-no-swiping'},[(_vm.menu.labelType === '横版')?_c('div',{staticClass:"cm-w-100 cm-h-100"},[_c('swiper',{ref:"menuSwiper",staticClass:"cm-w-100 cm-h-100 cm-flex-middle",class:_vm.menu.menuList.length <= 6 ? 'cm-flex-around' : '',style:({
        backgroundColor: _vm.menu.backgroundColor,
        borderRadius: _vm.menu.borderRadius + 'px'
      }),attrs:{"options":_vm.swiperOptions}},_vm._l((_vm.menu.menuList),function(item,index){return _c('swiper-slide',{key:item.id,staticClass:"cm-flex-column cm-center cm-h-100",class:_vm.menu.menuList.length <= 6 ? 'cm-flex-1' : '',staticStyle:{"height":"auto"},style:({ width: _vm.menu.menuList.length <= 6 ? 'auto' : '18.18%' })},[(_vm.menu.menuType !== 'icon')?_c('el-image',{class:_vm.menu.menuType === 'text' ? 'cm-icon-28' : 'cm-icon-16',attrs:{"src":_vm.menu.menuIndex === index ? item.imgSel : item.img}}):_vm._e(),(_vm.menu.menuType !== 'text')?_c('span',{staticClass:"cm-lh-1 cm-m-t-4",style:({
            fontSize: _vm.menu.fontSize + 'px',
            color:
              _vm.menu.menuIndex === index
                ? item.editForm.colorSel
                : item.editForm.color
          })},[_vm._v(_vm._s(item.label))]):_vm._e()],1)}),1)],1):_vm._e(),(_vm.menu.labelType === '竖版')?_c('div',{staticClass:"cm-h-100 cm-center"},[_c('swiper',{ref:"menuSwiper",staticClass:"cm-flex-column cm-w-100 menu",style:({
        backgroundColor: _vm.menu.backgroundColor,
        borderRadius: _vm.menu.borderRadius + 'px',
        maxHeight: _vm.menu.menuList.length <= 6 ? '360px' : '340px'
      }),attrs:{"options":_vm.swiperOptions}},_vm._l((_vm.menu.menuList),function(item,index){return _c('swiper-slide',{key:item.id,staticClass:"cm-flex-column cm-center cm-shrink-0",staticStyle:{"height":"60px!important"},on:{"click":function($event){_vm.menu.menuIndex = index}}},[(_vm.menu.menuType !== 'icon')?_c('el-image',{class:_vm.menu.menuType === 'text' ? 'cm-icon-28' : 'cm-icon-16',attrs:{"src":_vm.menu.menuIndex === index ? item.imgSel : item.img}}):_vm._e(),(_vm.menu.menuType !== 'text')?_c('span',{staticClass:"cm-lh-1 cm-m-t-4",style:({
            fontSize: _vm.menu.fontSize + 'px',
            color:
              _vm.menu.menuIndex === index
                ? item.editForm.colorSel
                : item.editForm.color
          })},[_vm._v(_vm._s(item.label))]):_vm._e()],1)}),1)],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }